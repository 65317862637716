import * as React from "react"
import styled from "styled-components";

import Layout from "../components/Layout"
import Banner from "../components/Banner"


const PageContent = styled.div`
padding:60px 0;
`;
const Container = styled.div`
max-width:1230px;margin:0 auto; padding:0 15px;
`;

const NotFoundPage = () => (
  <Layout>
    <Banner/>
    <PageContent>
    <Container>
    <h1>Strona nie istnieje</h1>
    <p>Skorzystaj z menu nawigacyjnego, aby wrócić na stronę.</p>
    </Container>
    </PageContent>
  </Layout>
)

export default NotFoundPage
