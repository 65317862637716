import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {
  FooterBlock,
  Container,
  FooterItem,
  FooterRgt,
  Logo,
  Title,
} from "./styled"

const Footer = () => {
  return (
    <>
      <FooterBlock>
        <Container>
          <FooterItem>
            <Logo>
              <Link to="/">
                <StaticImage src="../../assets/images/f-logo.png" alt="Kancelaria Notarialna" />
              </Link>
            </Logo>
            <FooterRgt>
              <div>
                <Title>Nawigacja</Title>
                <ul>
                  <li>
                    <Link to="/">Strona Główna</Link>
                  </li>
                  <li>
                    <Link to="/czynnosci-notarialne/akty-notarialne">Czynności prawne</Link>
                  </li>
                  <li>
                    <Link to="/kontakt">Kontakt</Link>
                  </li>
                  <li>
                    <Link to="/polityka-prywatnosci">polityka prywatności</Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                  <a href="mailto:m.dabrowska@notariusze.waw.pl">m.dabrowska@notariusze.waw.pl</a>
                  </li>
                  <li>
                  Tel. kom. <a href="tel:504295762">504 295 762</a>
                  </li>
                  <li>
                  <a href="mailto:szeszkowska@notariusze.waw.pl">szeszkowska@notariusze.waw.pl</a>
                  </li>
                  <li>
                  Tel. kom. <a href="tel:516031991">516 031 991</a>
                  </li>
                </ul>
              </div>
              <div>
              <ul>
                  <li>
                  <a href="tel:224054426">(22) 405 44 26</a>
                  </li>
                  <li>
                  <a href="tel:224054427">(22) 405 44 27</a>
                  </li>
                <li>
                  <a href="tel:224054428">(22) 405 44 28</a>
                </li>
              </ul>
              </div>
              <div>
                <address>
                <p>
                  Al. Niepodległości 245 lok. 103
                  <br /> 02-009 Warszawa
                  <br /> NIP: 701 005 17 64
                </p>
                </address>
                <p>Website by <a href="https://web-professor.pl" target='_blank'>WebProfessor</a>.</p>
              </div>
            </FooterRgt>
          </FooterItem>
        </Container>
      </FooterBlock>
    </>
  )
}

export default Footer
